import React from "react"
import { Heading } from "react-bulma-components"
import * as Styles from "./index.module.scss"
import Marker from "./marker"


const Map = () =>
	<div className={ Styles.container }>
		<div className={ Styles.mapWrapper }>
			<Marker
				style={ {
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				} }
			/>
		</div>

		<Heading
			subtitle={ true }
			textAlignment={ "right" }
			textSize={ 6 }
			style={ {
				marginTop: "0.25rem",
			} }
		>
			© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> / © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>
		</Heading>
	</div>

export default Map
