import React from "react"
import GatsbyImage from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"

import Heading from "react-bulma-components/lib/components/heading/heading"
import Content from "react-bulma-components/lib/components/content/content"

import * as Styles from "./infos.module.scss"


const Infos = () =>
	<StaticQuery
		query={ graphql`
			query {
				file ( relativePath: { eq: "images/avatar.jpg" } ) {
					image: childImageSharp {
						fluid {
							...GatsbyImageSharpFluid_withWebp 
						}
					}
				}
				site {
					siteMetadata {
						author
						contactinfos {
							address
							email
							phone
						}
						description
						services
						title
					}
				}
			}
		` }
		render={ data =>
			<React.Fragment>
				<Content size={ "medium" }>
					<div className={ Styles.avatarWrapper }>
						<GatsbyImage
							className={ Styles.avatar }
							fluid={ data.file.image.fluid }
						/>
					</div>

					<div className={ Styles.infosAlignmentWrapper }>
						<Heading
							size={ 4 }
							className={ "has-margin-top-none has-margin-bottom-sm" }
							renderAs={ "h2" }>
							{ data.site.siteMetadata.author }
						</Heading>

						<p dangerouslySetInnerHTML={ { __html: data.site.siteMetadata.contactinfos.address } } />

						<p className={ Styles.contactAnchors }>
							<span dangerouslySetInnerHTML={ { __html: data.site.siteMetadata.contactinfos.phone } } />
							<span dangerouslySetInnerHTML={ { __html: data.site.siteMetadata.contactinfos.email } } />
						</p>

						<Heading
							size={ 5 }
							className={ "has-margin-top-xl" }
							renderAs={ "h3" }>
							Leistungen
						</Heading>

						<ul>
							{ data.site.siteMetadata.services.map((text, index) =>
								<li key={ index }>{ text }</li>
							) }
						</ul>
					</div>
				</Content>
			</React.Fragment>
		}
	/>


export default Infos