import React from "react"

import Page from "../components/page"
import Slider from "../components/slider"
import Body from "../components/body"


const IndexPage = () => (
	<Page>
		<Slider/>
		<Body/>
	</Page>
)

export default IndexPage
