import React from "react"
import Slick from "react-slick"
import { graphql, StaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"

import * as Styles from "./index.module.scss";


const settings = {
	autoplay: true,
	infinite: true,
	speed: 500,
	arrows: false,
	pauseOnHover: false,
	slidesToShow: 1,
	slidesToScroll: 1
}

const Slider = () =>
	<StaticQuery query={ graphql`
		query {
            allFile (
            	filter: {
            		relativePath: { regex: "/images\\/slider/" }
            		}
            	) {
    			edges {
      				node {
        				image: childImageSharp {
          					fluid {
            					...GatsbyImageSharpFluid_withWebp
          					}
        				}
                	}
    			}
  			}
		}
	` }
	render={ ({ allFile: files }) =>
		<Slick { ...settings }>
			{ files.edges.map(({ node }, index) =>
				<GatsbyImage
					key={ index }
					className={ Styles.image }
					fluid={ node.image.fluid }
				/>
			) }
		</Slick>
	}/>

export default Slider