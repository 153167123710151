import React from "react"

import Container from "react-bulma-components/lib/components/container/container"
import Columns from "react-bulma-components/lib/components/columns/columns"

import Infos from "./infos"
import Map from "../map"


const Body = () =>
	<Container className={ "has-margin-bottom-xl" }>
		<Columns>
			<Columns.Column className={ "is-half is-4-tablet is-3-desktop" }>
				<Infos/>
			</Columns.Column>

			<Columns.Column className={ "is-full is-8-tablet is-9-desktop" }>
				<Map/>
			</Columns.Column>
		</Columns>
	</Container>

export default Body