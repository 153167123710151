import React from "react"

import * as Styles from "./marker.module.scss"
import MarkerSrc from "./marker.svg";

const Marker = (props) =>
	<img
		src={ MarkerSrc }
		className={ Styles.marker }
		alt={ "Map Marker" }
		{ ...props }
	/>

export default Marker
